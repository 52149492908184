import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { PostPageRenderModel } from '../external/blocks-widget/post-page-render-model';
import { initI18N } from '../external/common/services/controller-translate';
import { fetchMetadata } from './metadata';
import { getPostAndCategories } from './post';

interface FetchInitialDataParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const readingSessionId = `${parseInt(
    `${Math.random() * 10000000}`,
    10,
  )}-${Date.now()}`;

  let initialData: Awaited<ReturnType<typeof getInitialData>>;
  const warmupDataKey = 'PostPageWarmupData';

  const warmupDataGet = () => {
    try {
      const value = JSON.parse(wixCodeApi.window.warmupData.get(warmupDataKey));
      return value;
    } catch {
      return undefined;
    }
  };

  if (
    flowAPI.experiments.enabled('specs.wixBlog.UseWarmupStateInNewPostPage') &&
    flowAPI.environment.isSSR
  ) {
    initialData = await getInitialData({ wixCodeApi, instanceId, flowAPI });
    wixCodeApi.window.warmupData.set(
      warmupDataKey,
      JSON.stringify(initialData),
    );
  } else {
    initialData =
      warmupDataGet() ??
      (await getInitialData({ wixCodeApi, instanceId, flowAPI }));
  }

  initI18N(initialData.translations);

  return new PostPageRenderModel({
    post: initialData.post,
    tags: initialData.post.tags ?? [],
    metadata: {
      ...initialData.metadata,
      isLiked: Boolean(initialData.post.isLiked),
    },
    readingSessionId,
    categories: initialData.categories,
    appData: initialData.appData,
    instanceId,
    wixCodeApi,
  });
};

interface GetInitialDataParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

const getInitialData = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
}: GetInitialDataParams) => {
  const { post, categories, appData, translations } =
    await getPostAndCategories({
      wixCodeApi,
      instanceId,
      flowAPI,
    });

  const metadata = await fetchMetadata({
    wixCodeApi,
    flowAPI,
    postSlug: post.slug,
    postId: post.id,
  });

  return {
    post,
    categories,
    appData,
    translations,
    metadata,
  };
};
